import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Provider } from 'react-redux';
import errorLogger from './analytics/ErrorLogger';
import './css/app.css';
import './css/global.css';
import { externalQrScan } from './functions/externalQRScan';
import facebookPixel from './functions/facebookPixel';
import store from './redux/store';
import Routers from './router';
import { newRelic } from './utils/newRelic';
import GlobalContext from './GlobalContext';
import { getSessionItem, setSessionItem } from './webStorage/sessionStorage';
import { v4 as uuidv4 } from 'uuid';
import { setLocalItem } from './webStorage/localStorage';
import { detect } from 'detect-browser';
import { setCheckCartToken, getCartTokenLS } from './functions/getCartToken';
import { jitsuClient } from '@jitsu/sdk-js';
import {
  cookiesCartTokenTrack,
  setCookieTableInfoTrack,
} from './analytics/tracker/events/bugTrack';
// import { getMatomoScript } from './functions/getMatomoScript';
import { getMicrosoftClarityScript } from './functions/getMicrosoftClarityScript';
import { lockWindowMount, lockWindowUnmount } from './functions/lockWindow';
newRelic();
window.__bglogger = errorLogger();
window.__bgtracker = jitsuClient({
  key: process.env.REACT_APP_JITSU_KEY,
  tracking_host: process.env.REACT_APP_JITSU_URL,
});
function App() {
  const {
    REACT_APP_FACEBOOK_PIXEL_ID,
    REACT_APP_MATOMO_SERVER,
    REACT_APP_MATOMO_SRC,
    REACT_APP_MATOMO_SITE_ID,
    REACT_APP_MICROSOFT_CLARITY_ID,
  } = process.env;
  const [nameId, setnameId] = useState('');
  const [otFromUrl, setotFromUrl] = useState('');
  const [redirectFromQr, setredirectFromQr] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['cart_token']);
  const [scheduleTime, setScheduledTime] = useState(null);
  const [loading, setLoading] = useState(false);
  // const cartTokenFromLS = getCartTokenLS('');

  // url param queries
  let urlSearchQuery = queryString.parse(window.location.search);

  // Recommendatons sessionId
  useEffect(() => {
    const sessionIdFromSession = getSessionItem('sessionId');
    const sessionIdFromQuery = urlSearchQuery?.sessionId;

    // Do not set sessionId if already present in session storage
    if (!sessionIdFromSession) {
      setSessionItem('sessionId', uuidv4());
    }

    // Set session id from query params if present
    if (sessionIdFromQuery) {
      setSessionItem('sessionId', sessionIdFromQuery);
    }
  }, []);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.textContent = getMatomoScript(
  //     REACT_APP_MATOMO_SERVER,
  //     REACT_APP_MATOMO_SRC,
  //     REACT_APP_MATOMO_SITE_ID
  //   );
  //   document.head.appendChild(script);
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);
  useEffect(() => {
    const script = document.createElement('script');
    script.textContent = getMicrosoftClarityScript(REACT_APP_MICROSOFT_CLARITY_ID);
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  useEffect(() => {
    removeCookie('cart_token', { path: '/' });
    const cartTokenFromLS = getCartTokenLS('');
    const cartToken = setCheckCartToken(cartTokenFromLS);
    cookiesCartTokenTrack({
      fileName: 'App.js',
      functionName: 'useEffect: 1',
      cartToken: cartToken || cartToken + '',
    });
    // setCookie('cart_token', cartToken, { path: '/' });
  }, []);
  useEffect(() => facebookPixel(REACT_APP_FACEBOOK_PIXEL_ID), [REACT_APP_FACEBOOK_PIXEL_ID]);
  const fnBrowserDetect = () => {
    const browser = detect();
    setLocalItem('browser', browser.name);
  };
  useEffect(() => {
    const cartTokenFromLS = getCartTokenLS('');
    if (
      urlSearchQuery?.cartToken &&
      urlSearchQuery?.cartToken !== 'undefined' &&
      urlSearchQuery?.cartToken !== 'null' &&
      cartTokenFromLS !== urlSearchQuery?.cartToken
    ) {
      cookiesCartTokenTrack({
        fileName: 'App.js',
        functionName: 'useEffect: 2',
        cartToken: urlSearchQuery?.cartToken || urlSearchQuery?.cartToken + '',
      });
      // setCookie('cart_token', urlSearchQuery?.cartToken, { path: '/' });
    }
    fnBrowserDetect();
  }, []);
  useEffect(() => {
    let urlData = window.location.href;
    urlData = JSON.stringify(urlData).replace('?', '?&');
    if (urlData.includes('menu.php')) {
      urlData = JSON.parse(urlData);
      let queries = queryString.parse(urlData);
      if (queries?.rest_name) {
        setnameId(queries?.rest_name);
      }
      if (queries.date && queries.slot) {
        setScheduledTime({ date: queries.date, slot: queries.slot });
      }
      if (queries.ot) {
        setotFromUrl(queries.ot);
      }
    } else if (urlData.includes('set_restaurant_qr.php')) {
      setLoading(true);
      externalQrScan(urlData).then(({ redirectedURL, tableIdFromUrl, tableNo }) => {
        if (tableIdFromUrl && tableNo) {
          setCookie('tableId', tableIdFromUrl, { path: '/' });
          setCookie('tableNo', tableNo, { path: '/' });
          setCookieTableInfoTrack({
            fileName: 'App.js',
            functionName: 'externalQrScan',
            tableId: tableIdFromUrl + '',
            tableNo: tableNo + '',
          });
        }
        setredirectFromQr(redirectedURL);
      });
    }
  }, []);

  return (
    <>
      <GlobalContext.Provider value={{ loading, setLoading }}>
        <CookiesProvider>
          <Provider store={store}>
            <div
              className='container-fluid main_app pl-0 pr-0 select-none'
              style={{ minHeight: `${window.innerHeight}px` }}>
              <Routers
                redirect={nameId.toLowerCase()}
                ot={otFromUrl}
                redirectFromQr={redirectFromQr}
                scheduleTime={scheduleTime}
              />
            </div>
          </Provider>
        </CookiesProvider>
      </GlobalContext.Provider>
    </>
  );
}

export default App;
