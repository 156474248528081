let scrollY = window.scrollY;

function syncHeight() {
  document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  scrollY = window.scrollY;
}

export const lockWindowMount = () => {
  syncHeight();
  document.documentElement.classList.add('is-locked');
};

export const lockWindowUnmount = () => {
  document.documentElement.classList.remove('is-locked');
  window.scrollTo(0, scrollY);
};
